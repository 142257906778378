/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
    @extend .bg-cover;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70vh;
    min-height: 700px;
    background-color: $blue;
    @include media-breakpoint-down(sm) {
        height: initial;
        min-height: initial;
        padding-top: 90px;
    }

    .ariane {
        font-family: "Graphik";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.18em;
        text-transform: uppercase;
        color: white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: inline-table;
        text-decoration: none;
        padding-top: 150px;
        a {
            color: #808080;
            text-decoration: none;
        }
    }

    .item-content {
        width: 100%;
        padding: 100px 0;
        @include media-breakpoint-down(sm) {
            padding: 30px 0;
        }

        h1 {
            font-family: "Cambon";
            font-style: italic;
            font-weight: 400;
            font-size: 64px;
            line-height: 84px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #edf2f9;
            @include media-breakpoint-down(md) {
                font-size: 40px;
                line-height: 50px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 36px;
                line-height: 44px;
            }
        }

        .sstitle {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #ffffff;
        }

        .desc {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            text-align: center;
            letter-spacing: -0.01em;
            color: rgba(255, 255, 255, 0.8);
        }
        .txt-line {
            .cms {
                position: relative;
                text-align: left;
                padding-left: 20px;
                &:before {
                    content: "";
                    height: 100%;
                    width: 1px;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }
    }
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
    background: white;
    .custom-card-link {
        display: block;
        text-decoration: none;
    }

    /*
    * Card img
    */
    .card-img-container {
        display: flex;

        position: relative;
        overflow: hidden;
        // height: 400px;

        &:after {
            content: "";
            display: block;
            padding-top: 100%;
        }

        .card-img {
            width: 100%;
            // height: 400px;
            transition: transform 2s $easeOutExpo;
            will-change: transform;
            display: flex;
            // vertical-align: middle;
            // align-items: center;

            padding: 1.5rem 2rem;

            // img {
            //     width: 310px;
            //     height: 310px;

            //     @include media-breakpoint-down(xl) {
            //         width: 250px;
            //         height: 250px;
            //     }

            //     @include media-breakpoint-down(lg) {
            //         width: 220px;
            //         height: 220px;
            //     }
            // }
        }
    }

    /*
    * Card content
    */
    .card-content {
        text-align: center;
        padding: 30px 15px 30px 15px;
        transition: all 0.3s ease-out;
        position: relative;
        .circle {
            height: 85px;
            width: 85px;
            left: 0;
            right: 0;
            top: -45px;
            border-radius: 50%;
            margin: auto;
            position: absolute;
            background-color: white;
            overflow: hidden;
            img {
                margin: auto;
            }
        }
        .block-img {
            img {
                position: absolute;
                top: 130px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
        h2 {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 26px;
            text-align: center;
            min-height: 80px;
            letter-spacing: -0.01em;
            color: rgba(30, 41, 56, 0.8);
            @include media-breakpoint-down(lg) {
                min-height: 130px;
            }
            @include media-breakpoint-down(sm) {
                min-height: initial;
            }
        }
        .link {
            color: $blue2;
            font-family: "Graphik";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.18em;
            text-transform: uppercase;
            display: block;
        }
    }

    /*
    * Hover state
    */
    &:hover {
        .card-img-container {
            .card-img {
                transform: scale(1.05);
            }
        }
        .card-content {
            background-color: $blue;
            transition: all 0.5s ease-out;
            h2,
            .link {
                color: $white;
            }
        }
    }
}

#page-team-archive {
    background: $light-blue;
    .banner {
        .item-content {
            padding: 0 0 100px 0 !important;
            @include media-breakpoint-down(sm) {
                padding: 0 0 20px 0 !important;
            }
        }
    }
    a {
        text-decoration: none;
    }
    .custom-card {
        position: relative;

        @include media-breakpoint-down(xs) {
            margin: auto;
            max-width: 240px;
        }

        .card-img-container {
            height: 250px;

            @include media-breakpoint-down(sm) {
                max-width: 240px;
                margin: auto;
            }

            @include media-breakpoint-down(xs) {
                max-width: 240px;
            }
            .card-img {
                @include media-breakpoint-down(xs) {
                    max-width: 290px;
                }
            }
        }

        .card-content {
            display: flex;

            position: static;

            padding: 2rem 1rem;

            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;

            h2 {
                min-height: 0;
            }

            .rs {
                display: flex;

                gap: 1.5rem;

                a {
                    display: flex;
                    width: 1.5rem;
                    height: 1.5rem;

                    position: relative;
                    z-index: 2;

                    justify-content: center;
                    align-items: center;
                }

                img {
                    display: block;
                    width: auto;
                    height: auto;
                    max-width: 1.25rem;
                    max-height: 1.25rem;
                }
            }
        }

        & > a {
            position: absolute;
            inset: 0;
            z-index: 1;
        }

        @media (hover:hover) {
            .card-content {    
                h2 {
                    transform: translateY(1.25rem);
                    transition: transform .35s ease;
                }
    
                .rs {
                    opacity: 0;
                    transition: opacity .35s ease;
                }
            }

            &:hover {
                h2 {
                    transform: translateY(0);
                }
    
                .rs {
                    opacity: 1;
                }
            }
        }

        @media (hover:none) {
            .card-content {
                background-color: $blue;

                h2 {
                    color: $white;
                }
            }
        }
    }
    .listcard {
        margin-top: -250px;
        @include media-breakpoint-down(sm) {
            margin-top: 60px;
        }
    }
}

#page-incubator-archive {
    .listcard {
        margin-top: -250px;
    }
    .custom-card {
        .card-content {
            background-color: $light-grey;
            padding: 55px 24px 30px 23px;
            .circle {
                padding: 12px;
            }

            .block-img {
                max-height: 60px;
                max-width: 120px;
                text-align: center;
                margin: auto;
                img {
                    max-height: 60px;
                    top: 0;
                    position: relative;
                }
            }
        }
        &:hover {
            h2,
            .link {
                color: initial;
            }
        }
    }
    .card-img-container {
        height: 310px;
        .card-img {
            height: 310px;
        }
    }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}

/*
|
| CMS
|------
*/
.cms {
    span {
        display: inline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 30px;
    }

    h1 {
        @extend .title-xxl;
    }
    h2 {
        @extend .title-xl;
    }
    h3 {
        @extend .title-lg;
    }
    h4 {
        @extend .title-md;
    }
    h5 {
        @extend .title-sm;
    }
    h6 {
        @extend .title-xs;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    strong {
        font-weight: 500;
    }

    p {
        margin-bottom: 20px;
        font-family: "Graphik";
        font-weight: 400;
    }

    ul {
        margin-bottom: 20px;
        padding-left: 20px;

        li {
            list-style: disc;

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            ul {
                li {
                    list-style: circle;
                }
            }
        }
    }

    ol {
        margin-bottom: 20px;
        padding-left: 20px;
    }

    &.cms-no-break {
        p {
            margin-bottom: 0;
        }
    }

    blockquote {
        position: relative;

        width: 80%;

        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            margin: auto;
        }

        &:before {
            content: "";

            position: absolute;

            background-image: url("../img/icon/quote-left.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;

            background-image: url("../img/icon/quote-right.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

            @include media-breakpoint-down(md) {
                transform: translateX(60px) translateY(-50px);
            }
        }
    }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: $headerHeight;

    @include media-breakpoint-down(lg) {
        padding-top: $headerHeightSm;
    }
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
    width: 100px;
    display: inline-block;

    &.cl-center {
        margin-left: auto;
        margin-right: auto;
    }

    svg {
        display: inline-block;
        width: 100%;
        height: auto;
        fill: $black;

        circle {
            stroke: $black;
        }
    }

    &.ajax-loader {
        display: none;
    }
}

.listcard-news {
    .custom-card {
        .card-content {
            padding: 30px 20px 30px 20px;
            transition: all 0.3s ease-out;
            min-height: 270px;
            @include media-breakpoint-down(lg) {
                min-height: 310px;
            }
            @include media-breakpoint-down(sm) {
                min-height: initial;
            }
            h2 {
                font-family: "Cambon";
                font-style: normal;
                font-weight: 300;
                font-size: 21px;
                line-height: 30px;
                text-align: center;
                letter-spacing: -0.02em;
                padding-bottom: 10px;

                @include media-breakpoint-down(sm) {
                    min-height: initial;
                    margin-bottom: 15px;
                }
            }
            .ctnt {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 27px;
                text-align: center;
                letter-spacing: -0.01em;
                color: rgba(30, 41, 56, 0.8);
            }
        }
        &:hover {
            .card-content {
                transition: all 0.3s ease-out;
                p {
                    color: white;
                }
            }
        }
    }
}

#ot-sdk-btn-floating .ot-floating-button__front {
    opacity: 0;
    pointer-events: none;
}
