/*
|--------------------
|      Community
|--------------------
*/

.page-template-community {
    #page-community {
        .custom-card {
            .card-img-container {
                height: 300px;
                .card-img {
                    height: 300px;
                }
            }
            .card-content-details {
                h2 {
                    font-family: "Cambon";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 32px;
                    line-height: 42px;
                    color: $blue;
                }

                .city,
                .details {
                    font-weight: 500;
                    color: $blue;
                }
                .ctnt {
                    color: $blue;
                }
                .date {
                    font-family: "Graphik";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 27px;
                    letter-spacing: -0.01em;
                    color: $blue2;
                }
            }
            .card-content-com {
                text-align: center;
                @include media-breakpoint-down(md) {
                    text-align: left;
                    margin-top: 20px;
                }
                h3 {
                    font-family: "Cambon";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 26px;
                    text-align: center;
                    @include media-breakpoint-down(md) {
                        text-align: left;
                    }
                    i {
                        font-weight: bold;
                    }
                }
                img {
                    height: 60px;
                }
            }
        }
        .loaded-posts {
            .loadMoreBtn {
                margin: 30px auto 0 auto;
                display: table;
                &:hover {
                    .loadMoreBtn-label {
                        color: $blue;
                    }
                }
            }
        }
        .btn.loadMoreBtn {
            color: #333333;
            text-align: center;
        }

        .btn.loadMoreBtn:hover {
            text-decoration: none;
        }
    }
    .block-contact {
        width: 100%;
        z-index: 4;
        position: relative;
        .bgc-grey {
            background-color: $light-grey;
            min-height: 552px;
            margin-bottom: -80px;
            @include media-breakpoint-down(md) {
                height: initial;
            }
            h2 {
                font-style: italic;
            }
            .desc {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: $dark-grey2;
            }
        }
        .nl {
            margin-top: 30px;
        }
        .gform_heading {
            display: none;
        }
        .gform_footer {
            padding: 16px 0 0 0;
        }
        form {
            input[type="email"] {
                background: none;
            }
        }
        .txt-c {
            text-align: left !important;
            a {
                margin-left: 20px;
                padding: 22px 55px;
            }
        }
        button {
            margin-left: 20px;
            padding: 23px 80px 21px 80px;
        }
        .txt-info {
            font-family: "Graphik";
            font-size: 16px;
            line-height: 23px;
            font-weight: 400;
            color: $blue;
        }
        .gfield_consent_label {
            padding-top: 4px;
            padding-left: 35px;
            display: block;
            font-family: "Sharp Grotesk";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 25px;
            color: #9d9da1;
            text-align: left;
        }
        .ginput_container_consent {
            position: relative;
            margin-top: 42px;
            margin: auto;

            a {
                color: $blue;
                text-decoration: underline;
            }

            .gfield_required {
                display: none;
            }

            .gfield_consent_label {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: $blue;
            }
            input[type="checkbox"] {
                position: absolute;
                cursor: pointer;
                border: none;
                left: 0;
                top: 5px;
                padding: 0;
                margin: 0;
                background-color: initial !important;
            }

            input[type="checkbox"]:before {
                content: "";
                display: block;
                position: absolute;
                width: 22px;
                height: 22px;
                top: 0;
                left: 0;
                border: 1px solid $blue;
                background-color: transparent;
            }

            input[type="checkbox"]:checked:after {
                content: "";
                display: block;
                position: absolute;
                width: 14px;
                height: 14px;
                top: 4px;
                left: 4px;
                background-color: #000000;
            }
        }
    }
    #footer {
        z-index: 3;
        padding-top: 160px !important;
        .item-cols {
            padding-top: 160px !important;
        }
    }
}

.swiper-com {
    overflow: hidden;
    position: relative;
    .swiper-slide {
        height: 300px;
        background-position: center;
        background-size: cover;
        cursor: pointer;
        img {
            display: none;
        }
    }
    .swiper-pagination {
        bottom: 25px !important;
        left: 0;
        width: 100%;
        position: absolute;
        z-index: 1;
        text-align: center;
        margin: 0 !important;
        .swiper-pagination-bullet {
            width: 48px;
            height: 3px;
            border-radius: 0;
            background: white;
            opacity: 1;
            margin: 0 9px;
            position: relative;
            &.swiper-pagination-bullet-active {
                &::before {
                    content: "";
                    border: 1px solid white;
                    position: absolute;
                    left: -5px;
                    right: -5px;
                    top: -4px;
                    padding: 5px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    padding: 20px 0;
                }
            }
        }
    }
}

.close-button {
    display: none;
    height: 45px;
    width: 45px;
    color: #fff;
    position: fixed;
    top: 15px;
    right: 15px;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    z-index: 9999;
    opacity: 0.75;
    &:before {
        content: "×";
    }
    &:hover,
    &:active {
        opacity: 1;
    }
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    .swiper-com {
        height: 100%;
        .swiper-slide {
            background: none !important;
            cursor: default;
            display: flex;
            vertical-align: middle;
            align-items: center;
            height: auto;
            margin-left: auto;
            img {
                margin: auto;
                display: block;
                max-height: 80%;
                max-width: 80%;
            }
        }
    }
    .close-button {
        display: block;
    }
}

.galleryphoto {
    .w100 {
        transition: all ease 0.4s;
        .fullscreen {
            transition: all ease 0.4s;
        }
    }
    .card-img-container{
        display: block;   
    }

}

.overlay-com {
    background-color: black;
    position: fixed;
    opacity: 0.9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: none;
    transition: all ease 0.4s;
}
