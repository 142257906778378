/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black: #000000;
$very-dark-grey: #141414;
$dark-grey: #424242;
$dark-grey2: #48515d;
$grey: #999;
$very-light-grey: #fafafa;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);

$white: #ffffff;
$blue: #1e2938;
$blue2: #1a4779;
$light-blue: #edf2f9;
$very-light-grey: #e5e5e5;
$light-grey: #f0f1f1;

/*
|
| Assign colors
|---------------
|
*/
$border-color: $grey;
$text-color: $very-dark-grey;
$default-background-overlay: $black-opacity;

/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .c-{color}
|
*/
$colors: (
  //Required colors
  "black": $black,
  "very-dark-grey": $very-dark-grey,
  "dark-grey": $dark-grey,
  "dark-grey2": $dark-grey2,
  "grey": $grey,

  "white": $white,
  "$very-light-grey": $very-light-grey,
  "blue": $blue,
  "blue2": $blue2,
  "light-blue": $light-blue,
  "light-grey": $light-grey
);
