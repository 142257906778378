/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
    background: $blue;

    font-family: "Graphik";
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: white;

    .block-logo {
        img {
            margin: auto;
            display: block;
        }
    }

    .rs {
        display: flex;
        width: fit-content;
        margin: auto;
    }

    .footer-cols {
        display: flex;

        align-items: flex-start;
        justify-content: space-between;
        gap: 30px;

        color: #ffffff;

        strong {
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    @include media-breakpoint-down(md) {
        text-align: center;

        .footer-cols {
            flex-direction: column;
            align-items: center;
        }
    }

    .prefooter {
        a {
            font-weight: 500;
            color: white;
            text-decoration: none;
            transition: all ease 0.32s;
            @include media-breakpoint-down(sm) {
                display: block;
                padding-bottom: 18px;
            }
            &:hover {
                opacity: 0.7;
                transition: all ease 0.32s;
            }
        }
    }

    .ft-bottom {
        font-family: "Graphik";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 11px;
        border-top: 1px solid grey;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        a {
            color: #8e949c;
            text-decoration: none;
            transition: all ease 0.3s;
            @include media-breakpoint-down(sm) {
                display: block;
                padding-bottom: 18px;
            }
            &:hover {
                opacity: 0.7;
                transition: all ease 0.3s;
            }
        }
        .ot-sdk-show-settings {
            color: #8e949c;
            text-decoration: none;
            transition: all ease 0.3s;
            font-family: "Graphik";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 11px;
            text-transform: uppercase;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
                transition: all ease 0.3s;
            }
        }
        .txt-r {
            @include media-breakpoint-down(md) {
                text-align: center !important;
            }
        }
    }

    .footer-info {
        a {
            text-decoration: underline;
        }
    }
}
