@import url("https://fonts.googleapis.com/css2?family=Hind&display=swap");

$color-main: #11365f;
$color-main-rgba: rgba(17, 54, 95, 0.9);

/*
|--------------------
|     PAGE HOME
|--------------------
*/

.home {
    .banner-home {
        color: white;
        text-align: center;
        background-position: center;
        background-size: cover;
        background-color: #1e2938;
        height: 100vh;
        min-height: 720px;
        padding-top: 130px;
        overflow: hidden;
        position: relative;
        &.filter {
            &:before {
                content: "";
                opacity: 0.6;
                background-color: #1e2938;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        .item-content {
            padding-bottom: 50px;
            z-index: 2;

            h1 {
                color: rgba(255, 255, 255, 0.7);
            }

            .item-text {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 27px;
                text-align: center;
                letter-spacing: -0.01em;
                color: rgba(255, 255, 255, 0.8);
            }
            .item-link {
                font-family: "Graphik";
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                text-align: center;
                letter-spacing: 0.18em;
                text-transform: uppercase;
                text-decoration: none;
                color: #ffffff;
                position: relative;
                display: inline-block;
                opacity: 1;
                transition: all ease 0.3s;

                &:before {
                    content: "";
                    width: 40px;
                    height: 1px;
                    display: block;
                    float: left;
                    margin-top: 6px;
                    margin-right: 15px;
                    background-color: white;
                }
                &:hover {
                    opacity: 0.7;
                    transition: all ease 0.3s;
                }
            }
        }
        .home-anim {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            &-overlay {
                opacity: 0.65;
                background-color: #1e2938;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
            }
            .col {
                &:nth-child(1) {
                    padding-left: 0;
                }
                &:nth-child(4) {
                    padding-right: 0;
                }
                .elem {
                    margin-bottom: 70px !important;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 35px !important;
                    }
                }
            }
            img {
                width: 100%;
                padding: 0 17px;
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }
        }
    }
    .block-community {
        article {
            .card-img-container {
                &::after {
                    padding-top: 65%;
                }
                // height: 250px;
                // .card-img {
                //     height: 250px;
                // }
            }
        }
    }
}
