/*
|--------------------
|      Transactions
|--------------------
*/
.single-transactions {
    .block-ft-tran {
        .card-img-container {
            margin-top: 30px;
            @include media-breakpoint-down(xs) {
                max-width: 100%;
                margin: 0;
            }
            .card-img {
                @include media-breakpoint-down(xs) {
                    max-width: 100%;
                }
            }
        }
    }
    .back {
        color: white;
        display: block;
        font-family: "Graphik";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.18em;
        text-align: center;
        text-decoration: none;
        position: relative;
        width: fit-content;
        margin: auto;
        cursor: pointer;
        &:before {
            content: "";
            height: 1px;
            width: 100%;
            background-color: #fff;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .transac-content {
        color: white;
        text-align: center;
    }

    .infos {
        position: absolute;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        color: white;
        @include media-breakpoint-down(md) {
            bottom: 0px;
        }
        h2 {
            color: white;
            font-family: "Cambon";
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 32px;
            letter-spacing: 0.18em;
        }
        .role {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 26px;
            letter-spacing: 0.18em;
        }
        .details {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.18em;
        }
        .block-rs {
            svg {
                path {
                    fill: white;
                }
            }
        }
        .card-img-container {
            height: 360px;
            width: 100%;
            position: absolute;
            right: 0;
            left: 0;
            @include media-breakpoint-down(md) {
                height: 320px;
                max-width: 500px;
                position: relative;
                margin: 10px auto 30px auto;
            }
            @include media-breakpoint-down(xs) {
                height: 200px;
                max-width: 300px;
            }
            .card-img {
                height: 360px;
                @include media-breakpoint-down(md) {
                    height: 320px;
                    max-width: 500px;
                }
                @include media-breakpoint-down(xs) {
                    height: 200px;
                    max-width: 300px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .transactions-single {
            .card-img-container {
                .card-img {
                    height: 400px;
                    margin-bottom: 30px;
                }
            }
        }
    }
    .banner {
        @include media-breakpoint-down(md) {
            height: 450px;
            min-height: 450px;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 90px;
        }
    }
    h3 {
        font-family: "Cambon";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
    }
    .logo-single {
        max-height: 50px;
    }
}

#page-transactions-archive {
    background: $light-blue;
    .listcard {
        margin-top: -100px;
        @include media-breakpoint-down(sm) {
            margin-top: 40px;
        }
    }
}
.listtransac {
    .custom-card {
        .card-content {
            padding: 42px 20px 37px 20px;
            min-height: 200px;
            position: relative;
            @include media-breakpoint-down(sm) {
                min-height: initial;
            }
            h2 {
                font-size: 15px;
                line-height: 27px;
                min-height: initial;
            }
            .link {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 25px;
                @include media-breakpoint-down(sm) {
                    position: initial;
                }
            }
        }
        .card-img-container {
            // height: 290px;
            // @include media-breakpoint-down(xl) {
            //     height: 255px;
            // }
            // @include media-breakpoint-down(lg) {
            //     height: 290px;
            // }
            // @include media-breakpoint-down(md) {
            //     height: 330px;
            // }
            .card-img {
                display: flex;
                // height: 290px;
                // @include media-breakpoint-down(xl) {
                //     height: 255px;
                // }
                // @include media-breakpoint-down(lg) {
                //     height: 290px;
                // }
                // @include media-breakpoint-down(md) {
                //     height: 330px;
                // }
                .transac {
                    display: flex;
                    width: 100%;
                    padding: 0 1rem;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;
                    background-color: $white;
                    color: #1e2938;

                    &-logo {
                        display: block;
                        width: auto;
                        height: auto;
                        max-width: Min(65%, 260px);
                        max-height: 55px;
                    }

                    &-label {
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 1;
                    }
                }

                .transac1 {
                    height: 240px;
                    width: 240px;
                    background-color: white;
                    margin: auto;
                    padding-top: 40px;
                    padding-bottom: 40px;
                    .txt-aqui {
                        height: 80px;
                        font-family: "Graphik";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 15px;
                        text-align: center;
                        text-transform: uppercase;
                        color: #1e2938;
                        text-align: center;
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        span {
                            padding: 0 5px;
                            margin: auto;
                        }
                    }
                    .block-marque {
                        height: 80px;
                        margin: auto;
                        display: flex;
                        .marque {
                            max-width: 180px;
                            max-height: 78.33px;
                            width: initial;
                            height: initial;
                        }
                    }
                }
                .transac2 {
                    height: 240px;
                    width: 240px;
                    background-color: white;
                    margin: auto;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    .txt-aqui {
                        height: 66.66px;
                        font-family: "Graphik";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 15px;
                        text-align: center;
                        text-transform: uppercase;
                        color: #1e2938;
                        text-align: center;
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        span {
                            padding: 0 5px;
                            margin: auto;
                        }
                    }
                    .block-marque {
                        height: 66.66px;
                        margin: auto;
                        display: flex;
                        .marque {
                            max-width: 180px;
                            max-height: 66.66px;
                            width: initial;
                            height: initial;
                        }
                    }
                }
                .transac3 {
                    height: 240px;
                    width: 240px;
                    background-color: white;
                    margin: auto;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    &-logo {
                        max-width: Min(65%, 260px);
                    }
                    .txt-aqui {
                        height: 44px;
                        font-family: "Graphik";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 15px;
                        text-align: center;
                        text-transform: uppercase;
                        color: #1e2938;
                        text-align: center;
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                        span {
                            padding: 0 5px;
                            margin: auto;
                        }
                    }
                    .block-marque {
                        height: 44px;
                        margin: auto;
                        display: flex;
                        .marque {
                            max-width: 180px;
                            max-height: 45px;
                            width: initial;
                            height: initial;
                        }
                    }
                }
            }
        }
    }
}
