/*
|--------------------
|      TEAMS
|--------------------
*/
.single-team {
    .back {
        color: white;
        display: block;
        font-family: "Graphik";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.18em;
        text-align: center;
        text-decoration: none;
        position: relative;
        width: fit-content;
        margin: auto;
        cursor: pointer;
        &:before {
            content: "";
            height: 1px;
            width: 100%;
            background-color: #fff;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .infos {
        position: absolute;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        color: white;
        h2 {
            color: white;
            font-family: "Cambon";
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 43px;
            letter-spacing: 0.18em;
        }
        .role {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 26px;
            letter-spacing: 0.18em;
        }
        .details {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.18em;
        }
        .block-rs {
            svg {
                path {
                    fill: white;
                }
            }
        }
    }
    .team-single {
        .card-img-container {
            margin-top: -180px;
            height: 557px;
            @include media-breakpoint-down(md) {
                height: 320px;
                max-width: 270px;
                margin: 10px auto 30px auto;
            }
            .card-img {
                height: 557px;
                @include media-breakpoint-down(md) {
                    height: 320px;
                    max-width: 270px;
                }
            }
        }
    }
    .banner {
        @include media-breakpoint-down(md) {
            height: 300px;
            min-height: 300px;
        }
        @include media-breakpoint-down(sm) {
            height: 300px;
            min-height: 300px;
            padding-top: 90px;
        }
    }
}
