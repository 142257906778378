/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $font-family-default;
  color: $blue;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cambon";
}

h1 {
  font-family: "Cambon";
  font-weight: 400;
  font-size: 64px;
  line-height: 84px;
  @include media-breakpoint-down(md) {
    font-size: 40px;
    line-height: 46px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 30px;
    line-height: 36px;
  }
  i {
    color: white;
    font-family: "Cambon";
    font-weight: 300;
    font-style: italic;
  }
}
h2 {
  font-family: "Cambon";
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 40px;
  color: $blue;
  @include media-breakpoint-down(md) {
    font-size: 32px;
    line-height: 36px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 24px;
    line-height: 26px;
  }
}

.upp {
  text-transform: uppercase;
}

button,
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

.clearfix {
  clear: both;
  width: 100%;
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: inherit;
}

sup {
  top: -0.5em;
  font-size: 29px;
  font-weight: bold;
}

.z1 {
  z-index: 1;
}

img,
button {
  display: inline-block;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.clearfix {
  clear: both;
  width: 100%;
  display: block;
}

.m-a {
  margin: auto;
  display: block;
}

.d-t {
  display: table !important;
}

.td-0 {
  text-decoration: none;
}

.txt-c {
  text-align: center !important;
}

.txt-l {
  text-align: left !important;
}

.txt-r {
  text-align: right !important;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100vh;
}
.logo-c {
  display: none;
}
.rel {
  position: relative;
}
.load-more-btn {
  color: $blue !important;
}

.disp-desk {
  display: block !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.disp-mob {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}

.disp-desk-lg {
  display: block !important;
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.disp-mob-lg {
  display: none !important;
  @include media-breakpoint-down(lg) {
    display: block !important;
  }
}

.dflex {
  display: flex !important;
  vertical-align: middle;
  align-items: center;
}

.img-mob {
  margin: 0 auto 20px auto;
}

img {
  max-width: 100%;
}

.block-img {
  display: block;
  overflow: hidden;

  .bg-img {
    display: block;
    background-size: cover !important;
    transform: scale(1);
    transition: all ease 0.3s;
    background: initial;
    background-position: center;
  }
  &:hover {
    .bg-img {
      transform: scale(1.04);
      transition: all ease 0.3s;
    }
  }
}

.btn {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 23px 40px 21px 40px;
  background-color: $light-blue;
  border: 1px solid $light-blue;
  .btn-text {
    color: $blue2;
  }
  &:before {
    content: "";
    height: 1px;
    width: 40px;
    background-color: $blue2;
    display: block;
    position: absolute;
    left: -20px;
    right: 0px;
    top: 50%;
    transition: all ease 0.3s;
  }
  &:hover {
    animation: hover 0.4s forwards;
    background-color: $white;
    border: 1px solid $blue;
    &:before {
      content: "";
      width: 0px;
      left: 0px;
      right: 20px;
      transition: all ease 0.3s;
    }
    .btn-text {
      color: $blue;
    }
  }

  &-alt {
    animation: leave-alt 0.4s forwards;
    color: $blue;
    background-color: white;
    border: 1px solid white;
    &:hover {
      animation: hover-alt 0.4s forwards;
      border: 1px solid $blue;
      color: $blue;
    }
  }
}

@keyframes hover {
  from {
    box-shadow: inset 0 0 0 0.01px $blue2;
  }
  to {
    box-shadow: inset 30em 0 0 0.01px $blue2;
    color: #fff;
  }
}

@keyframes leave {
  from {
    box-shadow: inset -30em 0 0 0.01px $blue2;
    color: #fff;
  }
  to {
    box-shadow: inset 0 0 0 0.01px $blue2;
  }
}

@keyframes hover-alt {
  from {
    box-shadow: inset 0 0 0 0.01px white;
  }
  to {
    box-shadow: inset 30em 0 0 0.01px white;
    color: $blue2;
  }
}

@keyframes leave-alt {
  from {
    box-shadow: inset -30em 0 0 0.01px white;
    color: $blue2;
  }
  to {
    box-shadow: inset 0 0 0 0.01px white;
  }
}

.col-centered {
  margin: 0 auto;
}

.mt-80 {
  margin-top: -80px;
}

.block-img {
  display: block;
  overflow: hidden;
  height: 644px;
  width: 100%;
  .bg-img {
    display: block;
    background-size: cover;
    transform: scale(1);
    transition: all ease 0.4s;
    height: inherit;
    @include media-breakpoint-down(sm) {
      margin: auto;
    }
  }
}
