/*
|--------------------
|      HEADER
|--------------------
*/

body {
    &.menu-active {
        overflow: hidden;
    }
}

/*
|
| Header
|---------
*/
.link-menu {
    text-transform: uppercase;
    color: $very-dark-grey;
    font-size: 14px;
    font-weight: 400;
}

.bandeau {
    color: $white;
    padding: 11px 15px;
    font-size: 45px;
    line-height: 54px;
    width: 100%;
    display: block;
    z-index: 11;
    position: relative;
    text-align: center;
    top: 0;
    @include media-breakpoint-down(md) {
        font-size: 35px;
        line-height: 34px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 28px;
        line-height: 24px;
    }

    div {
        width: inherit;

        img {
            height: 50px;
            margin-right: 10px;
            @include media-breakpoint-down(sm) {
                height: 35px;
            }
        }
    }

    a {
        color: blue;
        background-color: $white;
    }
}

#header {
    position: fixed;
    z-index: 10;
    &.sticky-menu {
        background-color: $blue;
        border-bottom: 1px solid $blue;
        .header-container {
            padding-top: 10px;
            padding-bottom: 10px;
            transition: all ease 0.5s;
            .item-logo {
                img {
                    height: 50px;
                    max-width: inherit;
                    transition: all ease 0.5s;
                }
            }
        }
    }
}
#footer {
    position: absolute;
    z-index: 9;
}

#header,
#footer {
    width: 100%;

    .header-container {
        @extend .container;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 35px;
        padding-bottom: 35px;
        transition: all ease 0.5s;
        @include media-breakpoint-down(lg) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .item-logo {
            z-index: 1;
            position: relative;
            img {
                height: 60px;
                max-width: inherit;
                transition: all ease 0.5s;
            }
        }
    }

    .btn-menu {
        display: none;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        //width: 56px;
        //height: 56px;
        margin-top: 5px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        & > .item-burger {
            display: block;
            width: 25px;
            margin-top: -3px;

            & > span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: white;
                border-radius: 2px;

                &:nth-child(2) {
                    margin: 6px 0;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: $blue;
    overflow: auto;
    opacity: 0;
    visibility: hidden;

    .item-container {
        @extend .container;
        margin-top: 110px;
        ul {
            & > li {
                margin-bottom: 5px;
                margin-left: 15px;

                a {
                    text-transform: uppercase;
                    text-decoration: none;
                    color: white;
                    font-size: 24px;
                    font-weight: 500;
                    -webkit-font-smoothing: antialiased;
                }
            }
        }
    }
}

.item-nav {
    position: relative;
    @include media-breakpoint-down(lg) {
        display: none;
    }
    &.item-nav-right {
        padding-left: 50px;
        .item-menu {
            li {
                text-align: center;
            }
        }
    }
    &.item-nav-left {
        padding-right: 60px;
    }
    .item-menu {
        display: table;
        width: 100%;
        table-layout: fixed;
        li {
            display: table-cell;
            width: auto;
            position: relative;
            text-align: center;

            font-family: "Graphik";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 13px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            &.current-menu-item,
            &.current_page_parent {
                .item-link {
                    &:before {
                        content: "";
                        height: 1px;
                        width: 100%;
                        transition: all ease 0.3s;
                    }
                }
            }
            .item-link {
                font-family: "Graphik";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: white;
                text-decoration: none;
                position: relative;
                &:before {
                    content: "";
                    height: 1px;
                    width: 0%;
                    bottom: 0;
                    position: absolute;
                    background-color: white;
                    transition: all ease 0.3s;
                }
                &:hover {
                    &:before {
                        content: "";
                        height: 1px;
                        width: 100%;
                        transition: all ease 0.3s;
                    }
                }
            }
            .nav-drop {
                position: absolute;
                border-top: 1px solid #e6e6e6;
                border-bottom: 1px solid #e6e6e6;
                border-left: 1px solid #e6e6e6;
                border-right: 1px solid #e6e6e6;
                width: 100%;
                padding: 8px 0px 8px 0px;
                top: 26px;
                left: 0px;
                right: 0px;
                z-index: 9;
                display: none;
                background-color: rgba(255, 255, 255, 0.9);
                &.active {
                    top: 42px;
                }

                .item0 {
                    margin-top: -50px;
                    //padding-top: 20px;
                }

                li {
                    z-index: 9;
                    padding: 0 15px 10px 15px;
                    width: 100%;
                    display: inline-block;
                    text-align: left;
                    a {
                        font-family: "Avenir Next";
                        z-index: 9;
                        opacity: 0.8;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: initial;
                        display: block;
                        color: #000000;
                        width: 100%;
                        text-decoration: none;
                        transition: all ease 0.2s;
                    }

                    &:first-child {
                        margin-top: 0px;
                    }
                    &:hover {
                        color: #000;
                        opacity: 0.6;
                        transition: all ease 0.2s;
                    }
                }
            }
            &.menu-item-has-children {
                &:after {
                    content: "";
                    width: 14px;
                    height: 9px;
                    margin-left: 4px;
                    display: inline-block;
                    background-image: url("../img/icon/arrow-w.svg");
                    background-size: contain;
                    transition: all ease 0.6s;
                }
                &:hover {
                    &:after {
                        content: "";
                        transform: rotate(-180deg);
                        transition: all ease 0.6s;
                    }
                }
            }
        }
    }
}
.lang-container {
    position: relative;
    display: inline-flex;
    padding: 0 50px 0 0;
    @extend .link-menu;
    cursor: pointer;
    color: #ffffff;
    text-align: left;
    font-family: "Graphik";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
        font-size: 24px !important;
        font-weight: 400;
    }

    &:before {
        content: "";
        width: 8px;
        height: 6px;
        background-image: url("../img/icon/arr_down.svg");
        position: absolute;
        top: 4px;
        right: 33px;
        font-weight: 100;
    }
    .lang-item {
        margin-bottom: 4px;
    }

    ul {
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        opacity: 0;
        padding-top: 8px;
        visibility: hidden;
        transform: translateX(10px);
        transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;

        li {
            color: #ffffff;
            @include media-breakpoint-down(lg) {
                margin-left: 0px !important;
            }

            a {
                color: #ffffff;
                text-decoration: none;
                transition: all ease 0.3s;
                cursor: pointer;
                &:hover {
                    color: #ffffff;
                    transition: all ease 0.3s;
                }
            }
        }
    }

    &:hover {
        ul {
            opacity: 1 !important;
            visibility: visible !important;
            transform: translateX(0) !important;
        }
    }
}
