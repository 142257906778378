/*
|--------------------
|      SERVICES
|--------------------
*/

.accordion {
    .accordion-item {
        margin-bottom: 15px;
        position: relative;
        &.active {
            h3 {
                transition: all ease 0.5s;
                opacity: 0.6;
            }
        }
    }
    /* .accordion-item:after {
        position: absolute;
        content: "";
        border-top: 2px solid #dddddd;
        border-right: 2px solid #dddddd;
        height: 15px;
        width: 15px;
        right: 0;
        top: 10px;
        transform: rotate(133deg);
        transition: 300ms all linear;
    }
    .active.accordion-item:after {
        transform: rotate(-45deg);
    } */
    h3 {
        margin: 0;
        padding-bottom: 15px;
        position: relative;
        cursor: pointer;
        font-family: "Graphik";
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 26px;
        letter-spacing: 0.18em;
        text-transform: uppercase;
        color: $blue2;
        padding-left: 60px;
        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 26px;
        }

        &::before {
            content: "";
            height: 1px;
            width: 15px;
            content: "";
            height: 1px;
            width: 40px;
            background-color: $blue;
            float: left;
            margin-left: -60px;
            margin-top: 11px;
        }
        &:hover {
            transition: all ease 0.5s;
            opacity: 0.6;
        }
    }
    .accordion-body {
        padding: 10px 0;
        color: #525252;
        display: none;
    }
}
